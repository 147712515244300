.goc__test-container {
    display: flex;
    justify-content: wrap;
    flex-direction: column;
    padding: 4rem;
    
}

/* .wrapper{
    width: 300%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    transition: 1s;
} */

.goc__test-card-container {
    position: relative;
    width: 800px;
    min-width: 500px;
    min-height: 500px;
    padding: 2rem;
    overflow: hidden;

}

.goc__card-container2 {
    display: flex;
    justify-content: center;
    flex-direction: column
}

.test-card {
    display: flex;
    width: 700px;
    padding: 20px;
    margin: 0 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0);
    align-items: center;
    justify-content: center;
}

.test-card2 {
    display: flex;
    width: 700px;
    padding: 20px;
    margin: 0 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0);
    align-items: center;

}

.test-card-thumb {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    border-radius: 2px;

}

.card-thumb-marcus {
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;

}

.img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    border-radius: 20px;
    border-width: 40px;
   
}

.student-name {
    position: absolute;
    bottom: 5px;
    right: 5px;
    padding: 0px 5px;
    text-transform: capitalize;
    font-size: 20px;
    color:whitesmoke
}

.student-name-tom {
    position: absolute;
    bottom: 0px;
    right: 5px;
    padding: 0px 5px;
    text-transform: capitalize;
    font-size: 20px;
    color:whitesmoke
}
.student-name-tom p {
    font-size: 12px;
    font-weight: 800;
    color: grey
}

.student-name p {
    font-size: 12px;
    font-weight: 800;
    color: gray;
}

.test-card-body {
    width: 120%;
    min-height: 100%;
    height: auto;
    margin-left: 20px;
    position: relative;
    padding-bottom: 50px;
}

.review {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    color: rgb(182, 178, 178);
    padding-top: 0rem;
}

.rating {
    position: absolute;
    padding-top: 3rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    size: 15px;
    
}

.rating-star {
    position: absolute;
    padding-top: 4rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    size: 15px;
    
}


.rating-tom {
    position: absolute;
    padding-top: 5rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    size: 15px;
}


.star {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background: none;
}

.test-title {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 55px;
    line-height: 75px;
    letter-spacing: -0.03em;
    color: #fff;
    padding-top: 5rem;
}

@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }
