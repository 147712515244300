.goc__header {
    display: flex;
}


.goc__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 6rem ;
    margin-left: 6rem;
}

.goc__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 55px;
    line-height: 65px;
    letter-spacing: -0.03em;

}

.goc__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    color: #fff;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.goc__header-content__logo {
    width: 100%
}

.goc__header-content__logo img {
    width: 35px;
    height: 35px;
}


.goc__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.goc__header-content__input button {
    flex: 0.2;
    width: 100%;
    min-height: 50px;
    min-width: 25px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-bg);
    border: 2px solid #035bff;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    
}

.goc__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.goc__header-image img {
    width: 65%;
    height: 55%;
}



section {
    display: grid;
    place-items: center ;
    align-content: center;
    min-height: 100vh;
  }

.hiden {
    opacity: 0;
    transition: all 1s;
    transform: translateX(-100%);

}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0%);
}


@media screen and (max-width: 1050px) {
    .goc__header {
        flex-direction: column;
    }

    .goc__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .goc__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .goc__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .goc__header-content__logo {
        flex-direction: column;
    }

    .goc__header-content__logo p {
        margin: 0;
    }

    .goc__header-content__input input,
    .goc__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .goc__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .goc__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .goc__header-content__input input,
    .goc__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}