section {
    display: grid;
    place-items: center ;
    align-content: center;
    min-height: 40vh;
  }


.goc__footer {
    display: flex;
    flex-direction: row;
    
}

.goc__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    margin-bottom: 2rem;
}

.goc__footer-links_div {
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color:gray
}

a{
    color: rgb(175, 175, 179);
    text-decoration: none;
}

.goc__footer-links_div h4{
    font-size: 17px;
    line-height: 17px;
    margin: .5rem;
    font-family: var(--font-family);
    
}

.goc__footer-links_div p{
    font-size: px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
    font-family: var(--font-family);
}

.goc__footer-blow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.6rem;
}

.goc__footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    font-weight: 800;
}


.goc__footer-copyright p{
    margin-top: 2rem;
    font-size: 13px;
    line-height: 15px;
    color: gray;
    font-weight: 800;
}


@media screen and (max-width: 850px){
    .goc__footer-heading h1{
       font-size: 44px;
       line-height: 50px; 
    }
}

@media screen and (max-width: 550px){
    .goc__footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    .goc__footer-links div{
        margin: 1rem 0;
    }
    .goc__footer-btn p{
        font-size: 14px;

    }

    .goc__footer-below{
        flex-direction: column;
        justify-content: left;
    }

    .goc__footerbelow-links{
        flex-direction: column;
    }
    
    .goc__footer-below-links p{
    margin-top: 1rem;
    margin-left: 0rem;
    }
}

@media screen and (max-width: 400px) {
    .goc__footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
}