.goc__techs {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border: solid rgba(27, 120, 222, 1);


    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);


    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.goc__techs h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 75px;
    letter-spacing: -0.03em;
    color: #fff;
}

.goc__techs p {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    color: rgb(182, 178, 178);

    margin: 1rem 10rem;
}

.goc__techs button {
    flex: 0.2;
    width: 15%;
    min-height: 50px;
    min-width: 25px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-bg);
    border: 2px solid #035bff;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    
}

.span {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
}

/* section {
    display: grid;
    place-items: center ;
    align-content: center;
    min-height: 100vh;
  }

.hiden {
    opacity: 0;
    transition: all 1s;
    transform: translateX(-100%);

}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0%);
} */


@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }
