section {
    display: grid;
    place-items: center ;
    align-content: center;
    min-height: 40vh;
  }

  .goc__title-container{
    padding: 4rem 3rem 0rem 0rem;
  }

  .goc__title{
    margin-left: 8rem;
    margin-top: 4rem;
    font-size: 16px;
  }

  .goc__title h3{
    font-size: 18px;
    margin-left: 4rem;
  }

  .goc__title-h1{
    display: flex;
    align-items: center;
    margin: 2rem;

  }

   .sky{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: rgb(182, 178, 178);

    margin-left: 16rem;
  }

  .goc__title-h1 h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 55px;
    line-height: 65px;
    letter-spacing: -0.05em;
    color: white
  }

  .goc__title-h2{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 65px;
    letter-spacing: -0.05em;
    color: white;
   
    margin: 1rem 1rem 1rem 5rem;
  
  }

  .goc__title-h1 span{
    letter-spacing: -0.02em;
    margin-right: 0.10em;
  }

  .goc__title-button{
    display: flex;
    align-items: center;
    margin: 2rem;
  }


  .goc__title-button button{
    line-height: 28px;
    background: var(--color-bg);
    border: 2px solid #035bff;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    height: 45px;
    width: 120px;
    font-family: var(--font-family);
  }

  .goc__video-container{
    width: 60%;
    height: 100%;
    margin-top: 8rem;
    
    
  }

  .video{
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;
    
  }

  .goc__title-container2 h2{
    align-items: start;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 30px;
    color: #fff;
    margin: 5rem 0rem 2rem 1rem;
  }

  .goc__title-container2 {
    width: 55%;
  align-items: center;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    color: rgb(182, 178, 178);
    margin: 14rem 5rem 1rem 7rem;
  }
  
  .goc__title-container3 {
    width: 60%;
    align-items: center;  
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: rgb(182, 178, 178);
    margin: 2rem 2rem 3rem 9rem;
  }

  .goc__title-container3 p{
    margin: 1rem 2rem 0rem 2rem;
  }

  .goc__title-container3 h2{
    align-items: start;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 30px;
    color: #fff;
    margin: 2rem 3rem 3rem 1rem;
  }

  .goc__title-container3 h3{
    align-items: start;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 28px;
    line-height: 30px;
    color: #fff;
    margin: 2rem 3rem 3rem 1rem;
  }

  .you p{
    margin: 1rem 0rem 0rem 0rem;
  }


  .goc__ul{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    margin-right: 20rem;
  }
  
  ul{
    margin-block-start: 1em;
    margin-block-end: 2em;
    margin-top: 2rem;
    row-gap: 1.75rem;
    width: 70%;

  }

  .tag{
    margin-left: 1rem;
  }


.goc__list-link{
  display: flex;
  column-gap: 3.25rem;
  margin-left: 0rem;
  width: 100%;
}
.goc__list-link-2{
  display: flex;
  column-gap: -1.25rem;
  margin-left: 0rem;
}

.list-p{
  border-radius: 9999px;
  background-color:  rgb(36 44 56);
  padding: 0.5rem;
  color: rgb(245, 244, 242);
  text-align: center;
}

.list-p-2{
  font-size: 30px;
}
.goc__p-2{
  font-family: var(--font-family);
    color: rgb(208, 223, 255);
    font-size: 25px;
}

.goc__title-2{
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 35px;
  line-height: 65px;
  letter-spacing: -0.05em;
  color: white;
  margin: 9rem 2rem 0rem 0rem ;
}

.goc__info-container p{
  margin: 1rem 2rem 0rem 2rem;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.button button{
  line-height: 28px;
    background: var(--color-bg);
    border: 2px solid #035bff;
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    height: 65px;
    width: 160px;
    font-family: var(--font-family);
}

.button-sucess {
  display: flex;
  justify-content: center;
  margin: 2rem 16rem 0rem 0rem;
}

.button-sucess button{
  line-height: 28px;
    background: var(--color-bg);
    border: 2px solid #035bff;
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    height: 65px;
    width: 260px;
    font-family: var(--font-family);
}

.goc__title-3{
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 35px;
  line-height: 65px;
  letter-spacing: -0.05em;
  color: white;
  margin: 9rem 2rem 0rem 0rem ;
}

.goc__title-3 p{
  margin: 1rem 2rem 0rem 2rem;
}

.goc__master{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.goc__master h2{
  font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 30px;
    color: #fff;
    margin: 2rem 10rem 3rem 1rem;
}

.goc__master p{
  width: 65%;
    align-items: center;  
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: rgb(182, 178, 178);
    

}

.goc__included {
  width: 100%;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: rgb(182, 178, 178);
  margin: 12rem 4rem 2rem 6rem;
}

.goc__included h1{
  font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 30px;
    color: #fff;
  
}

.included-div{
  margin-right: 30rem;
}

.goc__quiz-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  padding: 0%;
  margin-top: 8rem;
  margin-bottom: 8rem;
}



.quiz h1{
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 3rem;

}

.cardbody {
  max-width: 100%;
}




  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }

  @media(max-width: 768px){
    .goc__video-container, .video{
      width: 100%;
    }
}

@media(max-width: 468px){
        .goc__video-container, .video{
          width: 100%;
    }
}
