.goc__programs {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    /* border: solid rgba(27, 120, 222, 1);
    background: var(--color-footer); */

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);


    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    margin: 4rem 4rem -10rem 0rem;
}

.goc__programs{
    width: 65%;
}


.goc__programs h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 55px;
    line-height: 75px;
    letter-spacing: -0.03em;
    color: #fff;
    margin-right: 16rem;
}

.goc__programs p {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    color: rgb(182, 178, 178);

    margin: 1rem 10rem 3rem 8rem;
}

.goc__programs-comprehensive{
    width: 50%;
}


.goc__programs-comprehensive h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 45px;
    letter-spacing: -0.03em;
    color: #fff;
    margin-bottom: 2rem;
    
    
}

.goc__programs-comprehensive p {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 25px;
    color: rgb(182, 178, 178);
    margin: 0rem 8rem 4rem 4rem;
}


.goc__programs-intro h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 75px;
    letter-spacing: -0.03em;
    color: #fff;
    margin: 0rem 10rem;
}

.goc__programs-intro p {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 25px;
    color: rgb(182, 178, 178);

    margin: 0rem 5rem;
}


  .video{
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;
  }





@media screen and (max-width: 850px) {
    .goc__programs
    .goc__programs-comprehensive {
        flex-direction: column;
    }

}

@media screen and (max-width: 650px) {
    .goc__programs h1,
    .goc__programs-comprehensive h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .goc__programs-comprehensive p,
    .goc__programs p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .goc__programs-comprehensive h1,
    .goc__programs h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .goc__programs-comprehensive p,
    .goc__programs p {
        font-size: 14px;
        line-height: 24px;
    }

}

@media(max-width: 768px){
    .goc__video-container, .video{
      width: 100%;
    }
}

@media(max-width: 468px){
        .goc__video-container, .video{
          width: 100%;
    }
}

