section {
    display: grid;
    place-items: center ;
    align-content: center;
    min-height: 40vh;
  }

  .goc__quiz-title-container{
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 55%;
    width: 55%;

  }

  .goc__quiz-title{
    margin: 2rem 6rem 2rem 2rem;
    
  }

  .goc__quiz-title h3{
    font-size: 20px;
    margin-left: 1rem;
  }

  .goc__quiz-title-h2{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.05em;
    color: white;
   }

   .goc__quiz-title-h2 h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 65px;
    letter-spacing: -0.05em;
    color: white;
    margin-left: 12rem;
   }

   .goc__quiz-title-2{
    margin: 1rem 0rem 0rem 10rem;
   }


  .quiz-sky{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: rgb(182, 178, 178);
    margin: 2rem;
  }

  .goc__video-container{
    width: 60%;
    height: 100%;
    margin-top: 8rem;
    overflow: hidden;
    
  }
  .video{
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;
    border-radius: 25%;
    
  }


  .goc__body-container{
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 55%;
    width: 55%;
   }

   .goc__body-title{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.05em;
    color: white;
    
   }

   .goc__body-info p{ 
    margin: 1rem 2rem 0rem 2rem;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 23px;
    color: rgb(182, 178, 178);
   }
   
   .goc__summary-title{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 55px;
    letter-spacing: -0.05em;
    color: white;
    margin: 6rem 0rem 0rem 0rem;
    width: 100%;
   }

   .goc__summary-title h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 40px;
    letter-spacing: -0.05em;
    color: rgb(208 223 255);
    margin: 2rem 0rem 0rem 1rem;
    width: 80%;
   }

   .goc__summary-info{
    margin: 2rem 2rem 12rem 0rem;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 23px;
    color: rgb(182, 178, 178);
    margin-left: 2rem;
   }

   .space{
    margin: 10rem;
   }

   .goc__footer-container{
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 55%;
    width: 55%;
    margin: -20rem 2rem 6rem 0rem;
   }

   .goc__master-container{
    margin-top: 6rem;
   }

   .card-body{
    display: flex;
    flex-direction: column;
    justify-items:flex-start;
    border: solid rgb(76, 165, 255);
    margin-right: 6rem;
    margin-top: 6rem;
    
   }
 
   .card-title h2{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.05em;
    color: white;
    width: 100%;
   }


   .card-title-h2{
    margin-left: 3rem;
   }

   .card-title-h2-2{
    margin-left: 9rem;
   }

   .goc__price h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 65px;
    letter-spacing: -0.05em;
    color: white;
    margin: 1rem 2rem 0rem 7rem;
    text-decoration: line-through;
   }

   .goc__price span{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 65px;
    letter-spacing: -0.05em;
    color: rgb(194, 77, 77);
    text-decoration: none;
   }

   .goc__price p{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 40px;
    color: rgb(208 223 255);
    margin: 0rem 6rem 0rem 4rem;
   }

   .goc__card-info{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 40px;
    color: rgb(182, 178, 178);
    margin: 2rem 4rem 0rem 2rem;
    width: 100%;
   }

  .program {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 40px;
    color: rgb(208 223 255);
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .program-p{
    margin-left: 2rem;
    color: rgb(208 223 255)
  }

  .card-body-button button{
    flex: 0.2;
    width: 45%;
    min-height: 50px;
    min-width: 35px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-bg);
    border: 2px solid #035bff;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    margin: 4rem 0rem 3rem 7rem;
  }

  .goc__bonuse-container{
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 55%;
    width: 55%;
    margin: -8rem 2rem 18rem 0rem;
   }

  .goc__bonuse-title{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 55px;
    letter-spacing: -0.05em;
    color: white;
    margin: 6rem 0rem 0rem 0rem;
    width: 100%;
   }
   .goc__bonuse-info{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    color: rgb(208 223 255);
    margin: 2rem 0rem 0rem 2rem;
    width: 100%;
   }

   .bonuse-still{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 55px;
    letter-spacing: -0.05em;
    color: white;
    margin-top: 6rem;
    margin-left: 6rem;
   }

   .bonuse-info-button button{
    flex: 0.2;
    width: 85%;
    min-height: 50px;
    min-width: 35px;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 30px;
    line-height: 28px;
    background: var(--color-bg);
    border: 2px solid #035bff;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    margin: 4rem 10rem 0rem 0rem;
  }



   @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }

@media(max-width: 768px){
    .goc__video-container, .video{
      width: 100%;
    }
}

@media(max-width: 468px){
        .goc__video-container, .video{
          width: 100%;
    }
}