
section {
    display: grid;
    place-items: center ;
    align-content: center;
    min-height: 40vh;
  }


.goc__container {
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.question__title {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 45px;
    line-height: 75px;
    letter-spacing: -0.03em;
    padding: 2rem 10rem 2rem 12rem;
}

.questions-simple > .active {
    display: block;
}

.goc__question-faq .inactive {
    display: none;
}

.goc__question-faq > div {
    margin-bottom: 1rem 1rem;
    padding: 0rem 3rem;
    border-radius: 5px;
    cursor: pointer;
}

.goc__question-faq-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.goc__question-faq-heading .active {
    font-family: var(--font-family);
    font-weight: 800;
}

.goc__question-faq-heading h3{
    font-family: var(--font-family);
    color: rgb(208, 223, 255);
    font-size: 25px;
}

.goc__question-faq p {
  color: rgb(163, 179, 188);
  line-height: 1.4;
  font-family: var(--font-family);
    font-weight: 800;
    padding: 0rem 1rem 1rem;
    font-size: 20px;

}

/* .horizental {
    color: rgba(27, 120, 222, 1);
} */

@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }
