.goc__thang {
    display: flex;
    flex-direction: column;
    padding: 2rem;

}

.goc__thang h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 55px;
    line-height: 75px;
    letter-spacing: -0.03em;
    color: #fff;
    align-items: start;
    flex-direction: column;
}


.goc__thang p {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    color: rgb(182, 178, 178);

    margin: 1rem 10rem;
}

/* section {
    display: grid;
    place-items: center ;
    align-content: center;
    min-height: 100vh;
  }

.hiden {
    opacity: 0;
    transition: all 1s;
    transform: translateX(-100%);

}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0%);
} */